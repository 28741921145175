import { ReactElement, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { adminWsClientList } from 'apis/restApi/adminApi';
import { ClientDetails } from 'defs/clientDetails';

import AdminWsClientRow from './AdminWsClientRow';

type IWsListState = {
  isLoading: boolean;
  error: string;
  data: ClientDetails[];
};

const initialState: IWsListState = {
  isLoading: false,
  error: '',
  data: [],
};

function AdminWsClients(): ReactElement {
  const [state, setState] = useState<IWsListState>(initialState);

  const handleListWsClients = async () => {
    setState({ ...initialState, isLoading: true });
    try {
      const clientsList = await adminWsClientList();
      if (clientsList.length === 0) {
        setState({ ...initialState, error: 'No clients' });
      } else {
        setState({ ...initialState, data: clientsList });
      }
    } catch (e) {
      setState({ ...initialState, error: e.toString() });
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Button variant="contained" color="secondary" disabled={state.isLoading} onClick={handleListWsClients}>
        List Ws Clients
      </Button>
      <div style={{ marginTop: '20px' }}>{state.error && <p>{state.error}</p>}</div>
      {state.data.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              Client ID
            </Grid>
            <Grid item xs>
              IP
            </Grid>
            <Grid item xs>
              Forwarded
            </Grid>
            <Grid item xs={4}>
              User Agent
            </Grid>
            <Grid item xs></Grid>
          </Grid>
          {state.data.map((client) => (
            <AdminWsClientRow key={client.clientId} client={client} />
          ))}
        </div>
      )}
    </div>
  );
}

export default AdminWsClients;
