import { createAction, Middleware } from '@reduxjs/toolkit';
import { WsClient } from 'apis/wsclient/wsClient';
import { RootState } from './store';

interface WsQuoteSub {
  id: string;
  tokens: number[];
}

interface WsQuoteUnsubAll {
  id: string;
}

export const wsConnect = createAction<undefined>('websocket/connect');
export const wsDisonnect = createAction<undefined>('websocket/disconnect');
export const wsSubscribe = createAction<WsQuoteSub>('websocket/subscribe');
export const wsUnsubscribe = createAction<WsQuoteSub>('websocket/unsubscribe');
export const wsUnsubAll = createAction<WsQuoteUnsubAll>('websocket/unsubscribeAll');

export function wsMiddleware(): Middleware<unknown, RootState> {
  console.log('middleware called');
  const wsClient = new WsClient();
  return (store) => (next) => (action) => {
    switch (action.type) {
      case wsConnect.toString():
        wsClient.connect(store.dispatch);
        break;
      case wsDisonnect.toString():
        wsClient.closeConn();
        console.log('websocket closed');
        break;
      case wsSubscribe.toString():
        console.log('sub msg received', action.payload);
        wsClient.addQuoteSubscription(action.payload.id, action.payload.tokens);
        break;
      case wsUnsubscribe.toString():
        console.log('unsub message received', action.payload);
        wsClient.delQuoteSubscription(action.payload.id, action.payload.tokens);
        break;
      case wsUnsubAll.toString():
        console.log('unsuball message received', action.payload);
        wsClient.unsubscribeAllForId(action.payload.id);
        break;
      default:
        return next(action);
    }
  };
}
