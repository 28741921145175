import { FormEvent, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import { validateLoginCredential } from 'apis/restApi/authApi';
import { loginSuccess, selectIsLoggedIn } from 'app/authSlice';

function LoginPage(): ReactElement {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [state, setState] = useState({ isLoading: false, error: '' });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setState({ isLoading: true, error: '' });
      await validateLoginCredential(user, pass);
      dispatch(loginSuccess());
    } catch (e) {
      console.log('ere', e);
      setState({ isLoading: false, error: e.toString() });
    }
  };

  if (isLoggedIn) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '20px' }}>
        <Alert severity="info">You are logged in</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      {state.error && <Alert severity="error">{state.error}</Alert>}
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div style={{ marginTop: '20px' }}>
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            value={user}
            disabled={state.isLoading}
            onChange={(e) => setUser(e.target.value)}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            id="password"
            type="password"
            label="Password"
            variant="outlined"
            value={pass}
            disabled={state.isLoading}
            onChange={(e) => setPass(e.target.value)}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <Button type="submit" color="primary" variant="contained" disabled={state.isLoading}>
            Log In
          </Button>
        </div>
      </form>
    </Container>
  );
}

export default LoginPage;
