import { apiUtils, appConstants } from 'apis/common';
import { AxiosResponse } from 'axios';

function checkError(resp: AxiosResponse) {
  if (resp?.data?.success === 'true' || resp?.data?.success === true) {
    return resp;
  }
  console.log(resp.data);
  // handle error cases
  const errMsg = resp.data?.err_msg;
  if (errMsg) {
    throw errMsg;
  }
  throw 'api returned error response';
}

export async function validateLoginCredential(user: string, pass: string): Promise<string> {
  console.log('send gin req');
  const respOrig = await apiUtils.callApi(appConstants.AUTH_LOGIN_URL, 'POST', { username: user, password: pass });
  const resp = checkError(respOrig);
  const data: string = resp.data?.payload;
  return data;
}
