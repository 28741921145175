import { Dispatch } from '@reduxjs/toolkit';

import { WS_URL } from 'apis/common/appConstants';
import { liveQuotesActions } from 'app/liveQuotesSlice';

import { quoteSubscribeMessage, quoteUnsubscribeMessage, WsMessage } from './wsMessages';
// import { createWebsocketConn } from './wsconn';
import { WsSubscription } from './wsSubscription';

// check custom ping message every 15s
const PING_CHECK_INTERVAL_MS = 15000;
// 1 minute timeout for receiving a custom ping message
const PING_CHECK_TIMEOUT_MS = 60000;

type Status = 'CONNECTED' | 'ERROR' | 'DISCONNECTED' | 'INIT';

export class WsClient {
  connStatus: Status;
  private wsConn: WebSocket | null;
  private pingCheckTimer: number | undefined;
  private lastPingTime: Date;
  private subscriptions: WsSubscription;

  constructor() {
    this.wsConn = null;
    this.connStatus = 'INIT';
    this.lastPingTime = new Date();
    this.pingCheckTimer = undefined;
    this.subscriptions = new WsSubscription();
  }

  private checkPingTimeout() {
    const now = new Date();
    const pingDiff = now.getTime() - this.lastPingTime.getTime();
    if (pingDiff > PING_CHECK_TIMEOUT_MS) {
      // eslint-disable-next-line no-console
      console.error('websocket custom ping receive timeout. closing connection');
      this.closeExisting();
    }
  }

  private closeExisting() {
    console.log('closing exsing conn');
    if (this.wsConn) {
      console.log('close begin called for active connection');
      this.wsConn.close();
      this.wsConn = null;
    }
    if (this.pingCheckTimer) {
      clearInterval(this.pingCheckTimer);
      this.pingCheckTimer = undefined;
    }
    this.connStatus = 'DISCONNECTED';
  }

  private processMessage(msgEvent: MessageEvent, dispatch: Dispatch) {
    try {
      if (typeof msgEvent.data !== 'string') {
        // we do not handle binary messages
        return;
      }
      const msg = JSON.parse(msgEvent.data);

      if (!msg) {
        return;
      }
      if (msg.data_type === 'ping') {
        this.lastPingTime = new Date();
        return;
      }
      if (msg.data_type === 'quote') {
        dispatch(liveQuotesActions.addQuote(msg.payload));
        return;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('err processing websocket message', err);
    }
  }

  // TODO reconnect

  public closeConn(): void {
    this.closeExisting();
  }

  public connect(dispatch: Dispatch): void {
    // const conn = createWebsocketConn();
    this.setNewConn(dispatch);
  }

  public setNewConn(dispatch: Dispatch): void {
    console.log('setting new connection');
    console.log('CURR OPENED', this.connStatus);
    this.closeExisting();
    const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const socketConn = new WebSocket(protocol + window.location.host + WS_URL);
    socketConn.binaryType = 'arraybuffer';

    this.wsConn = socketConn;
    this.lastPingTime = new Date();

    socketConn.onopen = () => {
      window.clearInterval(this.pingCheckTimer);
      this.pingCheckTimer = window.setInterval(() => this.checkPingTimeout(), PING_CHECK_INTERVAL_MS);
      console.log('CONN OPENED');
      this.connStatus = 'CONNECTED';
      console.log('WEBSOCKET CONNECTED', this.connStatus);
      this.resubscribeAll();
    };

    this.wsConn.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.error('error in websocket connection', error);
      this.connStatus = 'ERROR';
      this.closeExisting();
    };

    this.wsConn.onclose = (reason) => {
      // eslint-disable-next-line no-console
      console.error('websocket closed', reason);
      this.connStatus = 'DISCONNECTED';
      this.closeExisting();
    };

    // Parses Buffer and stores data to shared object
    socketConn.onmessage = (msg) => {
      this.processMessage(msg, dispatch);
    };
  }

  private sendMessage(msg: WsMessage) {
    console.log('sending msg', this.connStatus, msg);
    if (this.wsConn && this.wsConn.readyState === WebSocket.OPEN) {
      console.log('msg sent', this.wsConn?.readyState);
      this.wsConn.send(JSON.stringify(msg));
    } else {
      console.log('no msg sent', this.wsConn?.readyState);
    }
  }

  public resubscribeAll(): void {
    const tokens = this.subscriptions.getSubscribedQuotes();
    if (tokens.length > 0) {
      const msg = quoteSubscribeMessage(tokens);
      this.sendMessage(msg);
    }
  }

  public addQuoteSubscription(id: string, tokens: number[]): void {
    const tokenToSubscribe = this.subscriptions.addQuoteSubscription(id, tokens);
    if (tokenToSubscribe.length == 0) {
      console.log('no token to sub', tokenToSubscribe);
      return;
    }
    console.log('subscriting', tokenToSubscribe);
    const msg = quoteSubscribeMessage(tokenToSubscribe);
    this.sendMessage(msg);
  }

  public delQuoteSubscription(id: string, tokens: number[]): void {
    console.log('del', tokens);
    if (!tokens || tokens.length == 0) {
      console.log('del zero', tokens);
      return;
    }
    const tokenToUnsubscribe = this.subscriptions.delQuoteSubscription(id, tokens);
    if (tokenToUnsubscribe.length == 0) {
      console.log('none to del', tokens);
      return;
    }
    const msg = quoteUnsubscribeMessage(tokenToUnsubscribe);
    this.sendMessage(msg);
  }

  public unsubscribeAllForId(id: string): void {
    console.log('unsub all ', id);
    const tokenToUnsubscribe = this.subscriptions.unsubscribeAll(id);
    if (tokenToUnsubscribe.length == 0) {
      console.log('unsub all zero', id);
      return;
    }
    console.log('sending usub', id);
    const msg = quoteUnsubscribeMessage(tokenToUnsubscribe);
    this.sendMessage(msg);
  }
}

export interface WsConnInfo {
  connStatus: Status;
  wsConn: WebSocket | null;
}

// function createWsClient() {
//   return {
//     subscribe,
//     updateConn: (conn: WebSocket, brokerId: number) =>
//       update((state: WsClient) => {
//         state.setNewConn(conn, brokerId);
//         return state;
//       }),
//     closeConn: () =>
//       update((state: WsClient) => {
//         console.log('lcose called');
//         state.closeConn();
//         return state;
//       }),
//     addQuoteSubscription: (id: string, tokens: number[]) =>
//       update((state: WsClient) => {
//         state.addQuoteSubscription(id, tokens);
//         return state;
//       }),
//     delQuoteSubscription: (id: string, tokens: number[]) =>
//       update((state: WsClient) => {
//         state.delQuoteSubscription(id, tokens);
//         return state;
//       }),
//     unsubscribeAll: (id: string) =>
//       update((state: WsClient) => {
//         state.unsubscribeAll(id);
//         return state;
//       }),
//   };
// }

// export const wsClient = createWsClient();

// export function newWsConnection(brokerId: number) {
//   try {
//     console.log('connecting to ws');
//     let socketConn = createWebsocketConn(brokerId);
//     console.log('connected to ws');
//     wsClient.updateConn(socketConn, brokerId);
//   } catch (e) {
//     console.log('error in ws conn', e);
//     // TODO set status to error and retur
//   }
// }
