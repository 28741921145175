import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';

import { underlyingsReducer } from './underlyingsSlice';
import { derivativesReducer } from './derivativesSlice';
import { quotesReducer } from './quotesSlice';
import { liveQuotesReducer } from './liveQuotesSlice';
import { wsMiddleware } from './wsMiddleware';
import { authReducer } from './authSlice';

// tslint:disable-next-line
export const store: any = configureStore({
  reducer: {
    underlyings: underlyingsReducer,
    derivatives: derivativesReducer,
    quotes: quotesReducer,
    liveQuotes: liveQuotesReducer,
    auth: authReducer,
  },
  middleware: [...getDefaultMiddleware(), wsMiddleware()],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
