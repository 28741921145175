import { ReactElement } from 'react';

import AdminResetUnderlyings from './AdminResetUnderlyings';
import AdminResetDerivatives from './AdminResetDerivatives';
import AdminWsClients from './AdminWsClients';
import Grid from '@material-ui/core/Grid';

function AdminView(): ReactElement {
  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <AdminResetUnderlyings />
          </Grid>
          <Grid item xs>
            <AdminResetDerivatives />
          </Grid>
        </Grid>
      </div>
      <div>
        <AdminWsClients />
      </div>
    </>
  );
}

export default AdminView;
