import { ReactElement } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './views/Home';
import NotFound from './views/NotFound';
import Underlyings from './views/Underlyings';
import Derivatives from './views/Derivatives';
import AdminView from './views/AdminView';
import LoginPage from './views/LoginPage';
import TopNav from './containers/navigation/TopNav';
import AuthenticatedRoute from './AuthenticatedRoute';

export default function AppRouter(): ReactElement {
  return (
    <Router>
      <TopNav />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <AuthenticatedRoute path="/underlyings">
          <Underlyings />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/derivatives/:underlyingToken">
          <Derivatives />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/admin-internal">
          <AdminView />
        </AuthenticatedRoute>
        <Route path="/admin-login">
          <LoginPage />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
