import { ReactElement, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Instrument } from 'defs/instrument';
import { ClientDetails } from 'defs/clientDetails';
import { adminWsListSubscriptions, adminWsDisconnectClient } from 'apis/restApi/adminApi';

type IWsDisconnectState = {
  isLoading: boolean;
  error: string;
  data: string;
};

const initialWsDisconnectState: IWsDisconnectState = {
  isLoading: false,
  error: '',
  data: '',
};

type IWsSubscriptionState = {
  isLoading: boolean;
  error: string;
  data: Instrument[];
  dataLoaded: boolean;
};

const initialSubscriptionState: IWsSubscriptionState = {
  isLoading: false,
  error: '',
  data: [],
  dataLoaded: false,
};

function AdminWsClientRow({ client }: { client: ClientDetails }): ReactElement {
  const [state, setState] = useState<IWsDisconnectState>(initialWsDisconnectState);

  const handleDisconnectClient = async () => {
    setState({ ...initialWsDisconnectState, isLoading: true });
    try {
      const msg = await adminWsDisconnectClient(client.clientId);
      setState({ ...initialWsDisconnectState, data: msg });
    } catch (e) {
      setState({ ...initialWsDisconnectState, error: e.toString() });
    }
  };

  const [subState, setSubState] = useState<IWsSubscriptionState>(initialSubscriptionState);

  const handleListSubscriptions = async () => {
    setSubState({ ...initialSubscriptionState, isLoading: true });
    try {
      const instruments = await adminWsListSubscriptions(client.clientId);
      setSubState({ ...initialWsDisconnectState, ...{ data: instruments, dataLoaded: true } });
    } catch (e) {
      setSubState({ ...initialSubscriptionState, error: e.toString() });
    }
  };

  return (
    <div style={{ borderTop: '1px solid black', padding: '12px' }}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          {client.clientId}
        </Grid>
        <Grid item xs>
          IP: {client.remoteAddr}
        </Grid>
        <Grid item xs>
          Forwarded: {client.forwardedAddr}
        </Grid>
        <Grid item xs={4}>
          {client.userAgent}
        </Grid>
        <Grid item xs>
          <Button disabled={state.isLoading} onClick={handleDisconnectClient} variant="outlined" size="small">
            Disconnect
          </Button>
          <Button disabled={subState.isLoading} onClick={handleListSubscriptions} variant="outlined" size="small">
            List Subscriptions
          </Button>
        </Grid>
      </Grid>
      {(state.isLoading || state.error || state.data) && (
        <div style={{ borderTop: '1px dashed gray' }}>
          {state.isLoading && <p>Loading....</p>}
          {state.error && <p>{state.error}</p>}
          {state.data && <p>{state.data}</p>}
        </div>
      )}
      {(subState.isLoading || subState.error || subState.dataLoaded) && (
        <div style={{ borderTop: '1px dashed gray' }}>
          {subState.isLoading && <p>Loading....</p>}
          {subState.error && <p>{subState.error}</p>}
          <div style={{ display: 'inline-flex', padding: '4px', flexWrap: 'wrap' }}>
            {subState.dataLoaded && subState.data.length === 0 && <p>No data available</p>}
            {subState.dataLoaded &&
              subState.data.map((instrument) => (
                <div key={instrument.symbol} style={{ margin: '6px', backgroundColor: 'lightgray' }}>
                  {instrument.symbol} - {instrument.token}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminWsClientRow;
