import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchUnderlyings } from './underlyingsSlice';
// import { fetchDerivatives } from './derivativesSlice';
import { wsConnect } from './wsMiddleware';

import { checkLoginOnInit } from './authSlice';

export function useAppInitializer() {
  const dispatch = useDispatch();
  const isReady = true;
  // const isReady = useSelector(selectWebappReady);
  useEffect(() => {
    dispatch(checkLoginOnInit());
    dispatch(fetchUnderlyings());
    dispatch(wsConnect());
    setInterval(() => {
      dispatch(fetchUnderlyings());
    }, 10000);
  }, []);
  return {
    isReady,
  };
}
