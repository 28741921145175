import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { selectUnderlyings } from 'app/underlyingsSlice';
/* import ApiQuote from 'containers/ApiQuote'; */
import WsQuote from 'containers/WsQuote';

function Underlyings(): ReactElement {
  const underlyings = useSelector(selectUnderlyings);

  if (!underlyings) {
    return <div>No underlyings</div>;
  }

  return (
    <div>
      {underlyings.map((underlying) => (
        <div key={underlying.symbol}>
          <p>
            {underlying.symbol}
            {':  '}
            {/* <ApiQuote token={underlying.token} />
                  {'  '} */}
            <WsQuote token={underlying.token} />
            {'  '}
            <Link component={RouterLink} to={`/derivatives/${underlying.token}`} color="inherit">
              <button>{'show derivatives ->'}</button>
            </Link>
          </p>
        </div>
      ))}
    </div>
  );
}

export default Underlyings;
