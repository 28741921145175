import { useAppInitializer } from 'app/useAppInitializer';
import React, { ReactElement } from 'react';
import './App.css';

import AppRoutes from './AppRoutes';

function App(): ReactElement {
  const { isReady } = useAppInitializer();
  if (!isReady) {
    return <p>Loading...</p>;
  }
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
