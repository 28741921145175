import axios, { Method, AxiosError } from 'axios';

function parseErrorAsString(err: AxiosError): string {
  const code = err?.response?.status;
  if (code === 401) {
    window.localStorage.removeItem('sb_is_logged_in');
  }
  const errMsg = err.response?.data?.err_msg;
  if (errMsg) {
    return errMsg;
  }
  if (code) {
    const statusText = err.response?.statusText;
    return `Error ${code} ${statusText}`;
  }

  return 'unknown error error occured';
}

export async function callApi(url: string, method: Method, data?: any): Promise<any> {
  try {
    const config = Object.assign({
      method,
      url,
      data,
      withCredentials: true,
    });
    const resp = await axios(config);
    return resp;
  } catch (e) {
    throw parseErrorAsString(e);
  }
}
