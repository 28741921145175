import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import { Quote } from 'defs/quote';
import { quotesApi } from 'apis/restApi';

// State type
export interface QuotesState {
  quotes: Record<number, Quote>;
}

// API ACTIONS
export const fetchQuote = createAsyncThunk('quote/fetch', async (token: number) => {
  try {
    const response = await quotesApi(token);
    return response;
  } catch (err) {
    // thunkApi.dispatch(
    //   globalErrorSlice.actions.addNewAlert({
    //     title: 'User initialization error',
    //     msg: err.toString(),
    //     type: 'danger',
    //   }),
    // );
    // TODO handle error
    console.error('error in quotes api', err);
    throw err;
  }
});

// REDUCER and ACTIONS
const quotesSlice = createSlice({
  name: 'quotesData',
  initialState: {
    quotes: {},
  } as QuotesState,
  reducers: {
    reset: (state) => {
      state.quotes = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuote.fulfilled, (state, action) => {
      state.quotes[action.payload.token] = action.payload;
    });
  },
});

export const quotesReducer = quotesSlice.reducer;
export const quotesActions = quotesSlice.actions;

// SELECTORS
const selectQuotesState = (state: any): QuotesState => state.quotes;
export const selectQuotes = createSelector(selectQuotesState, (state: QuotesState) => state.quotes);
export const makeSelectQuoteForToken = () =>
  createSelector(
    selectQuotes,
    (_: any, token: number): number => token,
    (quotes, token) => quotes[token],
  );
