import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { Quote } from 'defs/quote';

// State type
export interface LiveQuotesState {
  quotes: Record<number, Quote>;
}

// REDUCER and ACTIONS
const liveQuotesSlice = createSlice({
  name: 'liveQuotesData',
  initialState: {
    quotes: {},
  } as LiveQuotesState,
  reducers: {
    reset: (state) => {
      state.quotes = {};
    },
    addQuote: (state, action: PayloadAction<Quote>) => {
      state.quotes[action.payload.token] = action.payload;
    },
  },
});

export const liveQuotesReducer = liveQuotesSlice.reducer;
export const liveQuotesActions = liveQuotesSlice.actions;

// SELECTORS
const selectLiveQuotesState = (state: any): LiveQuotesState => state.liveQuotes;
export const selectLiveQuotes = createSelector(selectLiveQuotesState, (state: LiveQuotesState) => state.quotes);
export const makeSelectLiveQuoteForToken = () =>
  createSelector(
    selectLiveQuotes,
    (_: any, token: number): number => token,
    (quotes, token) => quotes[token],
  );
