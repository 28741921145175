import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

// State type
export interface IAuthState {
  isLoggedIn: boolean;
}

// API ACTIONS
export const loginSuccess = createAsyncThunk('admin-auth/login-success', async () => {
  try {
    console.log('lgon success called');
    window.localStorage.setItem('sb_is_logged_in', 'true');
    return;
  } catch (err) {
    // thunkApi.dispatch(
    //   globalErrorSlice.actions.addNewAlert({
    //     title: 'User initialization error',
    //     msg: err.toString(),
    //     type: 'danger',
    //   }),
    // );
    console.error('error in login success thunk', err);
    throw err;
  }
});

export const checkLoginOnInit = createAsyncThunk('admin-auth/check-login-on-init', async () => {
  try {
    const isLoggedIn = window.localStorage.getItem('sb_is_logged_in');
    return isLoggedIn === 'true';
  } catch (err) {
    // thunkApi.dispatch(
    //   globalErrorSlice.actions.addNewAlert({
    //     title: 'User initialization error',
    //     msg: err.toString(),
    //     type: 'danger',
    //   }),
    // );
    console.error('error in reading cookie');
    throw err;
  }
});

// REDUCER and ACTIONS
const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    isLoggedIn: false,
  } as IAuthState,
  reducers: {
    reset: (state) => {
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkLoginOnInit.fulfilled, (state, action) => {
      state.isLoggedIn = action.payload;
    });
    builder.addCase(loginSuccess.fulfilled, (state) => {
      state.isLoggedIn = true;
    });
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;

// SELECTORS
const selectAuthState = (state: any): IAuthState => state.auth;
export const selectIsLoggedIn = createSelector(selectAuthState, (state: IAuthState) => state.isLoggedIn);
