import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { List, Link, ListItem, Container, AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { selectIsLoggedIn } from 'app/authSlice';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    color: 'white',
  },
});

export default function TopNav(): ReactElement {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Container>
        <Toolbar>
          <Typography variant="h6"> SBULL Tech </Typography>
          {isLoggedIn && (
            <List className={classes.root}>
              <ListItem>
                <Link component={RouterLink} to="/" color="inherit">
                  Home
                </Link>
              </ListItem>
              <ListItem>
                <Link component={RouterLink} to="/underlyings" color="inherit">
                  Underlyings
                </Link>
              </ListItem>
              <ListItem>
                <Link component={RouterLink} to="/admin-internal" color="inherit">
                  Admin
                </Link>
              </ListItem>
            </List>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
