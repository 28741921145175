export const WS_URL = `/api/ws`;

export const UNDERLYINGS_URL = `/api/underlyings`;

export function derivativesUrl(token: number): string {
  return `/api/derivatives/${token}`;
}
export function quotesUrl(token: number): string {
  return `/api/quotes/${token}`;
}

export const AUTH_LOGIN_URL = `/api/admin-auth/login`;

export const ADMIN_RESET_UNDERLYINGS_URL = `/api/admin-internal/reset-underlyings`;
export const ADMIN_RESET_DERIVATIVES_URL = `/api/admin-internal/reset-derivatives`;
export const ADMIN_LIST_WS_CLIENTS = `/api/admin-internal/socket/list-ws-clients`;

export function adminWsDisconnectClient(clientId: number): string {
  return `/api/admin-internal/socket/close-connection/${clientId}`;
}
export function adminWsListSubscriptions(clientId: number): string {
  return `/api/admin-internal/socket/list-subscriptions/${clientId}`;
}
