import { apiUtils, appConstants } from 'apis/common';
import { AxiosResponse } from 'axios';

import { ClientDetails } from 'defs/clientDetails';
import { Instrument } from 'defs/instrument';

function checkError(resp: AxiosResponse) {
  if (resp?.data?.success === 'true' || resp?.data?.success === true) {
    return resp;
  }
  console.log(resp.data);
  // handle error cases
  const errMsg = resp.data?.err_msg;
  if (errMsg) {
    throw errMsg;
  }
  throw 'api returned error response';
}
export async function adminResetUnderlyingsApi(): Promise<string> {
  const respOrig = await apiUtils.callApi(appConstants.ADMIN_RESET_UNDERLYINGS_URL, 'POST', null);
  const resp = checkError(respOrig);
  const data: string = resp.data?.payload;
  return data;
}

export async function adminResetDerivativesApi(): Promise<string> {
  const respOrig = await apiUtils.callApi(appConstants.ADMIN_RESET_DERIVATIVES_URL, 'POST', null);
  const resp = checkError(respOrig);
  const data: string = resp.data?.payload;
  return data;
}

export async function adminWsClientList(): Promise<ClientDetails[]> {
  const respOrig = await apiUtils.callApi(appConstants.ADMIN_LIST_WS_CLIENTS, 'GET', null);
  const resp = checkError(respOrig);
  const data: ClientDetails[] = resp.data?.payload;
  data.sort((a, b) => a.clientId - b.clientId);
  return data;
}

export async function adminWsDisconnectClient(clientId: number): Promise<string> {
  const respOrig = await apiUtils.callApi(appConstants.adminWsDisconnectClient(clientId), 'POST', null);
  const resp = checkError(respOrig);
  const data: string = resp.data?.payload;
  return data;
}

export async function adminWsListSubscriptions(clientId: number): Promise<Instrument[]> {
  const respOrig = await apiUtils.callApi(appConstants.adminWsListSubscriptions(clientId), 'GET', null);
  const resp = checkError(respOrig);
  const data: Instrument[] = resp.data?.payload;
  data.sort((a, b) => a.token - b.token);
  return data;
}
