import { ReactElement, useState } from 'react';
import Button from '@material-ui/core/Button';

import { adminResetUnderlyingsApi } from 'apis/restApi/adminApi';

function AdminResetUnderlyings(): ReactElement {
  const [state, setState] = useState({ isLoading: false, error: '', isComplete: false });

  const handleClick = async () => {
    setState({ isLoading: true, error: '', isComplete: false });
    try {
      await adminResetUnderlyingsApi();
      setState({ isLoading: false, error: '', isComplete: true });
    } catch (e) {
      setState({ isLoading: false, error: e.toString(), isComplete: false });
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" disabled={state.isLoading} onClick={handleClick}>
        Reset Underlyings
      </Button>
      {state.error && <p>state.error</p>}
      {state.isComplete && <p>Done</p>}
    </div>
  );
}

export default AdminResetUnderlyings;
