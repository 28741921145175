import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import { Instrument } from 'defs/instrument';
import { underlyingApi } from 'apis/restApi';

import { fetchQuote } from './quotesSlice';
// State type
export interface UnderlyingsState {
  isReady: boolean;
  underlyings: Instrument[];
}

// API ACTIONS
export const fetchUnderlyings = createAsyncThunk('underlyings/fetch', async (_, thunkApi) => {
  try {
    const response = await underlyingApi();
    response.forEach((underlying) => {
      thunkApi.dispatch(fetchQuote(underlying.token));
    });
    return response;
  } catch (err) {
    // thunkApi.dispatch(
    //   globalErrorSlice.actions.addNewAlert({
    //     title: 'User initialization error',
    //     msg: err.toString(),
    //     type: 'danger',
    //   }),
    // );
    // TODO handle error
    console.error('error in underlyings api', err);
    throw err;
  }
});

// REDUCER and ACTIONS
const underlyingsSlice = createSlice({
  name: 'underlyingsData',
  initialState: {
    underlyings: [],
    isReady: false,
  } as UnderlyingsState,
  reducers: {
    reset: (state) => {
      state.underlyings = [];
      state.isReady = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnderlyings.fulfilled, (state, action) => {
      state.underlyings = action.payload;
      state.isReady = true;
    });
  },
});

export const underlyingsReducer = underlyingsSlice.reducer;
export const underlyingActions = underlyingsSlice.actions;

// SELECTORS
const selectUnderlyingsState = (state: any): UnderlyingsState => state.underlyings;
export const selectUnderlyings = createSelector(selectUnderlyingsState, (state: UnderlyingsState) => state.underlyings);
export const selectUnderlyingsIsReady = createSelector(
  selectUnderlyingsState,
  (state: UnderlyingsState) => state.isReady,
);
