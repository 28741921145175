export class WsSubscription {
  private quoteSubscribers: Map<string, Set<number>>;

  private subscribedQuotes: Set<number>;

  constructor() {
    this.quoteSubscribers = new Map();

    this.subscribedQuotes = new Set();
  }

  // This function updates the subscripiton for quotes and return the list of new
  // tokens to be subscribed to
  addQuoteSubscription(id: string, tokens: number[]): number[] {
    const currSubscription = this.quoteSubscribers.get(id) || new Set();
    tokens.forEach((token) => {
      currSubscription.add(token);
    });
    this.quoteSubscribers.set(id, currSubscription);

    const subscriptionUpdated = new Set<number>();
    this.quoteSubscribers.forEach((quoteSet) => {
      quoteSet.forEach((token) => subscriptionUpdated.add(token));
    });

    const newTokensToSubscribe = [...subscriptionUpdated].filter((token) => !this.subscribedQuotes.has(token));

    this.subscribedQuotes = subscriptionUpdated;
    return newTokensToSubscribe;
  }

  getSubscribedQuotes(): number[] {
    return [...this.subscribedQuotes];
  }
  // This function updates the subscripiton for quotes and return the list of
  // tokens to be unsubscribed to
  delQuoteSubscription(id: string, tokens: number[]): number[] {
    const currSubscription = this.quoteSubscribers.get(id);

    if (!currSubscription) {
      return [];
    }

    tokens.forEach((token) => {
      currSubscription.delete(token);
    });

    if (currSubscription.size > 0) {
      this.quoteSubscribers.set(id, currSubscription);
    } else {
      this.quoteSubscribers.delete(id);
    }

    const subscriptionUpdated = new Set<number>();
    this.quoteSubscribers.forEach((quoteSet) => {
      quoteSet.forEach((token) => subscriptionUpdated.add(token));
    });

    const tokensToUnsubscribe = [...this.subscribedQuotes].filter((token) => !subscriptionUpdated.has(token));

    this.subscribedQuotes = subscriptionUpdated;
    return tokensToUnsubscribe;
  }

  // unsubscribes all for a given id and returns the tokens to unsubscribe
  unsubscribeAll(id: string): number[] {
    const currSubscription = this.quoteSubscribers.get(id);
    this.quoteSubscribers.delete(id);
    if (!currSubscription || currSubscription.size <= 0) {
      return [];
    }

    const subscriptionUpdated = new Set<number>();
    this.quoteSubscribers.forEach((quoteSet) => {
      quoteSet.forEach((token) => subscriptionUpdated.add(token));
    });

    const tokensToUnsubscribe = [...this.subscribedQuotes].filter((token) => !subscriptionUpdated.has(token));

    this.subscribedQuotes = subscriptionUpdated;
    return tokensToUnsubscribe;
  }
}
