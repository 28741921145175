import { ReactElement, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wsSubscribe, wsUnsubAll } from 'app/wsMiddleware';

import { makeSelectLiveQuoteForToken } from 'app/liveQuotesSlice';

interface QuoteInputProps {
  token: number;
}

function WsQuote({ token }: QuoteInputProps): ReactElement {
  const id = 'ws-quote-component-' + Math.random().toString(36).substr(2, 12);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('subscribe', token);
    dispatch(wsSubscribe({ tokens: [token], id }));
    return () => {
      dispatch(wsUnsubAll({ id }));
      console.log('unsubscribe', token);
    };
  }, []);
  const selectQuoteForToken = useMemo(makeSelectLiveQuoteForToken, []);
  const quote = useSelector((state) => selectQuoteForToken(state, token));
  const price = quote?.price;
  const displayPrice = price ? price.toFixed(2) : '--';

  return <span>{displayPrice}</span>;
}

export default WsQuote;
