import { useSelector } from 'react-redux';
import { ReactElement } from 'react';

import NotFound from './views/NotFound';
import { Route } from 'react-router-dom';
import { selectIsLoggedIn } from 'app/authSlice';

function AuthenticatedRoute({ children, ...rest }: any): ReactElement {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Route
      {...rest}
      render={() => {
        return isLoggedIn ? children : <NotFound />;
      }}
    />
  );
}

export default AuthenticatedRoute;
