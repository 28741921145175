import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from '@material-ui/core';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { fetchDerivatives, selectDerivatives, derivativesActions } from 'app/derivativesSlice';
/* import ApiQuote from 'containers/ApiQuote'; */
import WsQuote from 'containers/WsQuote';

function parseUnderlying(underlyingToken: string): number {
  try {
    if (!underlyingToken) {
      -1;
    }
    return parseInt(underlyingToken);
  } catch (err) {
    return -1;
  }
}

function Derivatives(): ReactElement {
  const { underlyingToken } = useParams<{ underlyingToken: string }>();
  let err = null;
  const utoken = parseUnderlying(underlyingToken);
  if (utoken <= 0) {
    err = `error parsing token ${underlyingToken} as number`;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (utoken >= 0) {
      dispatch(fetchDerivatives(utoken));
    }
    return () => {
      dispatch(derivativesActions.reset());
    };
  }, []);

  const derivatives = useSelector(selectDerivatives);

  if (!derivatives) {
    return <div>No derivatives</div>;
  }

  return (
    <div>
      <Link component={RouterLink} to="/underlyings" color="inherit">
        {'<-back'}
      </Link>
      {err ? <div>{err}</div> : null}
      {derivatives.map((derivatives) => (
        <div key={derivatives.symbol}>
          <p>
            {derivatives.symbol}
            {':  '}
            {/* <ApiQuote token={derivatives.token} />
                  {'  '} */}
            <WsQuote token={derivatives.token} />
          </p>
        </div>
      ))}
    </div>
  );
}

export default Derivatives;
