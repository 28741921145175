import { PACKET_TYPE } from './constants';

const CMD_SUBSCRIBE = 'subscribe';
const CMD_UNSUBSCRIBE = 'unsubscribe';

export interface WsMessage {
  msg_command: string;
  data_type: PACKET_TYPE;
  tokens: number[];
}

function quoteMessage(tokens: number[], cmd: string): WsMessage {
  return {
    msg_command: cmd,
    data_type: 'quote',
    tokens,
  };
}

export function quoteSubscribeMessage(tokens: number[]): WsMessage {
  return quoteMessage(tokens, CMD_SUBSCRIBE);
}

export function quoteUnsubscribeMessage(tokens: number[]): WsMessage {
  return quoteMessage(tokens, CMD_UNSUBSCRIBE);
}
