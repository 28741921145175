import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import { Instrument } from 'defs/instrument';
import { derivativesApi } from 'apis/restApi';

// State type
export interface DerivativesState {
  isReady: boolean;
  derivatives: Instrument[];
}

// API ACTIONS
export const fetchDerivatives = createAsyncThunk('derivatives/fetch', async (token: number) => {
  try {
    const response = await derivativesApi(token);
    return response;
  } catch (err) {
    // thunkApi.dispatch(
    //   globalErrorSlice.actions.addNewAlert({
    //     title: 'User initialization error',
    //     msg: err.toString(),
    //     type: 'danger',
    //   }),
    // );
    console.error('error in derivatives api', err);
    throw err;
  }
});

// REDUCER and ACTIONS
const derivativesSlice = createSlice({
  name: 'derivativesData',
  initialState: {
    derivatives: [],
    isReady: false,
  } as DerivativesState,
  reducers: {
    reset: (state) => {
      state.derivatives = [];
      state.isReady = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDerivatives.fulfilled, (state, action) => {
      state.derivatives = action.payload;
      state.isReady = true;
    });
  },
});

export const derivativesReducer = derivativesSlice.reducer;
export const derivativesActions = derivativesSlice.actions;

// SELECTORS
const selectDerivativesState = (state: any): DerivativesState => state.derivatives;
export const selectDerivatives = createSelector(selectDerivativesState, (state: DerivativesState) => state.derivatives);
export const selectDerivativesIsReady = createSelector(
  selectDerivativesState,
  (state: DerivativesState) => state.isReady,
);
